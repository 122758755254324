/*
  This is the index.css file, in here we have defined some general css rules that
  should apply for the entire application.
*/

@font-face {
  font-family: roboto;
  src: url(./../public/font/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: raleway;
  src: url(./../public/font/raleway/Raleway-Regular.ttf);
}

* {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: roboto;
}

html,
body {
  height: 100%;
  background-color: white;
  color: black;
  overflow: auto;
}

a {
  color: #b9c56c;
}

.btn {
  padding: 10px 25px;
  height: fit-content;
  width: fit-content;
  color: #b9c56c !important;
  background-color: #002809;
  border-radius: 7px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  margin: auto;
  display: block;
}

.cta {
  padding: 15px 40px;
  font-weight: normal;
}

h1,
h2,
h5 {
  font-family: raleway;
}

h1 {
  font-size: 40px;
  text-align: center;
  color: #002809;
}

h2 {
  color: #002809;
}
